import { useEffect, useState } from 'react'

interface ReturnsType {
  hasFocus: Boolean,
}

const useWindowFocus = (): ReturnsType => {
  const [ hasFocus, setHasFocus ] = useState<Boolean>(true)

  function _handleOnFocus () {
    setHasFocus(true)
  }

  function _handleOnBlur () {
    setHasFocus(false)
  }

  useEffect(() => {
    window.addEventListener('focus', _handleOnFocus)
    window.addEventListener('blur', _handleOnBlur)

    // ensure to remove the listener when the component will unmount
    return () => {
      window.removeEventListener('focus', _handleOnFocus)
      window.removeEventListener('blur', _handleOnBlur)
    }
  }, []) // Empty array ensures that effect is only run on mount

  return { hasFocus }
}

export default useWindowFocus
