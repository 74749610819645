import { Address } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import CheckoutInvoiceAddressSelectButton from '~/src/components/checkout/CheckoutInvoiceAddressSelectButton'
import ChooseCustomerAddress from '~/src/components/customers/addresses/ChooseCustomerAddress'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'

const CheckoutInvoiceAddress = () => {
  const { t } = useTranslations()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)

  const handleOnChoose = (address: Address) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      invoiceDetails: {
        ...state.invoiceDetails,
        address
      }
    }))
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}>
      <Typography>{`${t('checkout.invoice.chooseAddress')} *`}</Typography>
      <ResponsiveDialog
        activator={<CheckoutInvoiceAddressSelectButton />}
        maxWidth='xs'
        fullWidth
        Title={<Title
          variant='h5'
          component='p'
        >
          {t('checkout.invoice.chooseAddress')}
        </Title>}
      >
        <ChooseCustomerAddress
          closeOnSave={true}
          selected={checkoutForm.invoiceDetails.address}
          onChoose={handleOnChoose}
        />
      </ResponsiveDialog>
    </Box>
  )
}

export default CheckoutInvoiceAddress
