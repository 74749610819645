import { Box } from '@mui/material'
import CheckoutInvoiceDetails from '~/src/components/checkout/CheckoutInvoiceDetails'
import CheckoutReceiveInvoiceCheckbox from '~/src/components/checkout/CheckoutReceiveInvoiceCheckbox'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const CheckoutInvoicing = () => {
  const auth = useEoValue(AUTHENTICATION_STATE)
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)

  if (auth.loggedInAs !== 'customer' || !firm?.settings.modules.hasModuleInvoicing) {
    return null
  }

  return (
    <Box sx={{ py: 1 }}>
      <Title>{t('checkout.invoicing')}</Title>
      <CheckoutReceiveInvoiceCheckbox />
      <CheckoutInvoiceDetails />
    </Box>
  )
}

export default CheckoutInvoicing
