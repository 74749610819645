import { Theme as MuiTheme, Typography, useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import CheckoutActions from '~/src/components/checkout/CheckoutActions'
import CheckoutDeliveryAddress from '~/src/components/checkout/CheckoutDeliveryAddress'
import CheckoutInvoicing from '~/src/components/checkout/CheckoutInvoicing'
import CheckoutMessage from '~/src/components/checkout/CheckoutMessage'
import CheckoutShoppingCart from '~/src/components/checkout/CheckoutShoppingCart'
import Payments from '~/src/components/checkout/payments/Payments'
import CanOnlyOrderOnOpeningHour from '~/src/components/error/CanOnlyOrderOnOpeningHour'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import OrderingMethodsSummaryBoxContainer from '~/src/components/ordering-methods/OrderingMethodsSummaryBoxContainer'
import useValidateCheckoutDeliveryCostEffect from '~/src/hooks/checkout/useValidateCheckoutDeliveryCostEffect'
import useValidateCheckoutPaymentStatusEffect from '~/src/hooks/checkout/useValidateCheckoutPaymentStatusEffect'
import useValidateCheckoutTimeEffect from '~/src/hooks/checkout/useValidateCheckoutTimeEffect'
import { useRouterQuery } from '~/src/hooks/router/useRouterQuery'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CART_TOTAL_IS_ZERO_SELECTOR } from '~/src/stores/cart'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import CheckoutLoyaltySection from '~/src/components/checkout/loyalty/CheckoutLoyaltySection'

const CheckoutContainer = () => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()
  const { params } = useRouterQuery()
  const isSmallDevice = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('md'))
  const setCheckoutForm = useSetEoState(CHECKOUT_FORM_STATE)
  const totalIsZero = useEoValue(CART_TOTAL_IS_ZERO_SELECTOR)

  useValidateCheckoutTimeEffect()
  useValidateCheckoutDeliveryCostEffect()
  useValidateCheckoutPaymentStatusEffect()

  useEffect(() => {
    if (localStorage.getItem('checkoutForm') && params['cancelled']) {
      setCheckoutForm(JSON.parse(localStorage.getItem('checkoutForm') as string))
      localStorage.removeItem('checkoutForm')
    }
  }, [ params ])

  return (
    <>
      <CanOnlyOrderOnOpeningHour/>
      <GoBackToTheShopButton sx={{
        my: 2,
        [theme.breakpoints.down('md')]: {
          my: 1
        }
      }}/>
      <Card>
        <Title
          variant='h5'
          sx={{ pb: 2 }}
        >
          {t('checkout.title')}
        </Title>
        {isSmallDevice && <CheckoutShoppingCart/>}
        <OrderingMethodsSummaryBoxContainer/>
        <CheckoutDeliveryAddress/>
        <CheckoutInvoicing/>
        <CheckoutLoyaltySection/>
        {!totalIsZero && <Payments/>}
        <CheckoutMessage/>
        <Typography variant='body1'>
          {`* ${t('global.required')}`}
        </Typography>
        <CheckoutActions/>
      </Card>
    </>
  )
}

export default CheckoutContainer
