import { LinearProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import { useTranslations } from '~/src/hooks/useTranslations'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import { Player } from '@lottiefiles/react-lottie-player'
import CloseDialogButton from '~/src/components/navigation/CloseDialogButton'
import { CHECKOUT_PAYMENT_STATE } from '~/src/stores/checkout'
import { useEoValue } from '~/src/hooks/useEoState'
import { PaymentStateEnum } from '@eo-storefronts/eo-core'

interface Props {
  open?: boolean,
}

const OrderValidatingPaymentResponsiveDialog = ({ open }: Props) => {
  const { t } = useTranslations()
  const paymentState = useEoValue(CHECKOUT_PAYMENT_STATE)
  const [ showCloseDialogButton, setShowCloseDialogButton ] = useState<boolean>(false)
  const [ showSuccessAnimation, setShowSuccessAnimation ] = useState<boolean>(false)
  const [ showFailedAnimation, setShowFailedAnimation ] = useState<boolean>(false)
  const [ showLoader, setShowLoader ] = useState<boolean>(true)
  const orderValidatingPaymentDialogName = DialogNameEnum.ORDER_VALIDATING_PAYMENT

  useEffect(() => {
    if (!open) {
      return
    }

    setShowLoader(!paymentState || paymentState === PaymentStateEnum.PAID)
    setShowCloseDialogButton(!!paymentState && paymentState !== PaymentStateEnum.PAID)
    setShowFailedAnimation(!!paymentState && paymentState !== PaymentStateEnum.PAID)
    setShowSuccessAnimation(!!paymentState && paymentState === PaymentStateEnum.PAID)
  }, [ open, paymentState ])

  if (!open) {
    return null
  }

  return (
    <ResponsiveDialog
      openByDefault={open}
      showCloseButton={false}
      persistent={true}
      name={orderValidatingPaymentDialogName}
      Actions={showCloseDialogButton && <CloseDialogButton dialogName={orderValidatingPaymentDialogName} />}
    >
      {showFailedAnimation && <Player
        src='/images/lotties/failed.json'
        autoplay
        keepLastFrame
      />}
      {showSuccessAnimation && <Player
        src='/images/lotties/success.json'
        autoplay
        keepLastFrame
      />}
      <Typography variant='body1' sx={{ mb: 1.5 }} align='center'>
        {!paymentState && t('orders.validatingPayment.message')}
        {showSuccessAnimation && t('orders.validatingPayment.success')}
        {showFailedAnimation && paymentState === PaymentStateEnum.TIMEOUT && t('orders.validatingPayment.timeout')}
        {showFailedAnimation && paymentState !== PaymentStateEnum.TIMEOUT && t('orders.validatingPayment.error')}
      </Typography>
      {showFailedAnimation && paymentState === PaymentStateEnum.TIMEOUT && <Typography variant='body2' sx={{ mb: 1.5 }} align='center'>
        {t('orders.validatingPayment.timeout_subtext')}
      </Typography>}
      {showLoader && <LinearProgress/>}
    </ResponsiveDialog>
  )
}

export default OrderValidatingPaymentResponsiveDialog
