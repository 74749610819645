import Button from '~/src/components/mui-wrappers/buttons/Button'
import useCreateOrder from '~/src/hooks/checkout/useCreateOrder'
import useValidateCheckout from '~/src/hooks/checkout/useValidateCheckout'
import useValidateOrder, { OrderErrorType } from '~/src/hooks/orders/useValidateOrder'
import { useTranslations } from '~/src/hooks/useTranslations'
import { buttonClasses, useTheme } from '@mui/material'
import CssUtils from '~/src/utils/CssUtils'

const CheckoutConfirmButton = () => {
  const { t } = useTranslations()
  const { postOrder, loading } = useCreateOrder()
  const { errors: checkoutErrors } = useValidateCheckout()
  const { errors: orderErrors } = useValidateOrder()
  const theme = useTheme()
  const containerLightOrDark: 'light' | 'dark' = CssUtils.isColorLightOrDark(theme.palette.secondary.main)

  const disabled =
    !!checkoutErrors.length
    || !!orderErrors.filter((error: OrderErrorType) => error.variant === 'error').length

  return (
    <Button
      variant='contained'
      preventMultipleClick
      loading={loading}
      disabled={disabled}
      onClick={postOrder}
      sx={{
        [`&.${buttonClasses.disabled}`]: {
          color: containerLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.3)',
          backgroundColor: containerLightOrDark === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
        }
      }}
    >
      {t('footer.confirm_order')}
    </Button>
  )
}

export default CheckoutConfirmButton
