import { ButtonProps } from '@mui/material/Button/Button'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'
import useDialog from '~/src/hooks/useDialog'


interface Props extends Partial<Pick<ButtonProps, 'color' | 'variant' | 'sx'>> {
  small?: boolean,
  dialogName: DialogNameEnum,
}

const CloseDialogButton = ({ color = 'inherit', variant = 'outlined', small = false, sx, dialogName } : Props) => {
  const { dismiss } = useDialog()
  const { t } = useTranslations()

  const handleOnClick = () => {
    return dismiss(dialogName)
  }

  return (
    <Button
      small={small}
      color={color}
      variant={variant}
      sx={sx}
      onClick={handleOnClick}
    >
      {t('orders.validatingPayment.confirmBtn')}
    </Button>
  )
}

export default CloseDialogButton
