import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const CheckoutLoyaltyTitle = () => {
  const { t } = useTranslations()

  return (
    <Title>{t('checkout.loyaltyInfo')}</Title>
  )
}

export default CheckoutLoyaltyTitle
