import {
  CreateOrderPayload,
  Customer,
  CustomerLoyaltyVoucher,
  DateUtils,
  Firm,
  LanguageType,
  Modifier,
  ModifierGroup,
  OrderingMethodNames,
  Price,
  PriceList,
  Product,
  UuidService
} from '@eo-storefronts/eo-core'
import { getModifierPrice } from '~/src/services/ModifierService'
import { convertProductToModifier, getProductPriceList } from '~/src/services/ProductService'
import { AuthenticationState } from '~/src/stores/authentication'
import {
  CartProductModifierGroupStateInterface,
  CartProductModifierStateInterface
} from '~/src/stores/cart/cart-product-modifier-groups'
import { CheckoutFormState } from '~/src/stores/checkout'
import { CartProduct } from '~/src/types/CartProduct'

export interface RawOrderInfo {
  brand: Firm | null,
  firm: Firm | null,
  lang: LanguageType,
  slotId: number | null,
  cartProducts: CartProduct[],
  vouchers: CustomerLoyaltyVoucher[] | null,
}

export default class GenerateOrderPayloadService {
  private readonly _checkoutForm: CheckoutFormState
  private readonly _priceLists: PriceList[]
  private readonly _products: Record<string, Product>
  private readonly _auth: AuthenticationState
  private readonly _customer: Customer | null
  private readonly _modifierGroups: Record<string, ModifierGroup>
  private readonly _modifiers: Record<string, Modifier>
  private readonly _totalIsZero: boolean

  constructor(
    checkoutForm: CheckoutFormState,
    priceLists: PriceList[],
    products: Record<string, Product>,
    modifierGroups: Record<string, ModifierGroup>,
    modifiers: Record<string, Modifier>,
    auth: AuthenticationState,
    customer: Customer | null,
    totalIsZero: boolean
  ) {
    this._checkoutForm = checkoutForm
    this._priceLists = priceLists
    this._products = products
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers
    this._auth = auth
    this._customer = customer
    this._totalIsZero = totalIsZero
  }

  public generate({ brand, firm, lang, slotId, cartProducts, vouchers }: RawOrderInfo): CreateOrderPayload {
    if (!firm) {
      throw new Error('Firm must be set')
    }

    let planningDate = DateUtils.momentFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss')

    if (this._checkoutForm.orderingMethod.time) {
      planningDate = DateUtils.momentFormat(new Date(this._checkoutForm.orderingMethod.time), 'YYYY-MM-DDTHH:mm:ss')
    }

    const orderUid = UuidService.generate()
    const notes: string[] = []
    const payload: CreateOrderPayload = {
      header: {
        uid: orderUid,
        easystore_origin_id: 6,
        journal_id: firm.settings.quoteJournalId.toString(),
        date: DateUtils.momentFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        planning_date: planningDate,
        consumption_type: this._buildConsumptionType(),
        note: '',
        storefront_id: brand?.id as number
      },
      payments: [],
      lines: []
    }

    if (slotId) {
      payload.header.timeslot_id = slotId
    }

    if (
      this._checkoutForm.orderingMethod.tableMethod !== null
      && this._checkoutForm.orderingMethod.tableUid
      && this._checkoutForm.orderingMethod.tableNumber
    ) {
      payload.header.table_uid = this._checkoutForm.orderingMethod.tableUid
      payload.header.table_number = this._checkoutForm.orderingMethod.tableNumber
    }

    if (this._auth.loggedInAs === 'customer' && this._customer?.uid) {
      payload.header.customer_uid = this._customer.uid
      payload.header.notification_order_email = this._customer.email
      payload.header.notification_order_customer_name = `${this._customer.firstName} ${this._customer.lastName}`
      payload.header.notification_order_sms = this._customer.phone || undefined
      payload.header.customer_name = `${this._customer.firstName} ${this._customer.lastName}`
      payload.header.customer_phone_number = this._customer.phone || undefined
    }

    if (this._auth.loggedInAs === 'guest') {
      payload.header.notification_order_email = this._auth.guest?.email
      payload.header.notification_order_customer_name = `${this._auth.guest?.firstName} ${this._auth.guest?.lastName}`
      payload.header.notification_order_sms = this._auth.guest?.phone
      payload.header.customer_name = `${this._auth.guest?.firstName} ${this._auth.guest?.lastName}`
      payload.header.customer_phone_number = this._auth.guest?.phone
    }

    if (this._checkoutForm.moreInformation) {
      notes.push(this._checkoutForm.moreInformation)
    }

    if (this._checkoutForm.paymentMethod?.id !== 1 && !this._totalIsZero) {
      payload.header.payment_type_id = parseInt((this._checkoutForm.paymentMethod?.id || '0').toString())
    }

    if (this._checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY) {
      payload.header.customer_address_line = this._checkoutForm.orderingMethod.address?.street
      payload.header.customer_zip_code = this._checkoutForm.orderingMethod.address?.zipCode
      payload.header.customer_city = this._checkoutForm.orderingMethod.address?.locality
      payload.header.customer_country_id = parseInt(this._checkoutForm.orderingMethod.address?.country.id.toString() || '0')

      if (this._checkoutForm.orderingMethod.address?.bus) {
        payload.header.customer_address_line += `/${this._checkoutForm.orderingMethod.address?.bus}`
      }

      payload.header.delivery_address = {
        street: this._checkoutForm.orderingMethod.address?.street,
        zip_code: this._checkoutForm.orderingMethod.address?.zipCode,
        locality: this._checkoutForm.orderingMethod.address?.locality,
        house_number: this._checkoutForm.orderingMethod.address?.bus,
        country: {
          id: this._checkoutForm.orderingMethod.address?.country.id,
          name: this._checkoutForm.orderingMethod.address?.country.name[lang]
        }
      }

      payload.lines.push({
        sale_header_uid: orderUid,
        parent_sale_line_uid: null,
        uid: UuidService.generate(),
        quantity: 1,
        unit_price: this._checkoutForm.orderingMethod.deliveryCost.toString(),
        discount_rate: 0,
        item_uid: this._checkoutForm.orderingMethod.deliveryItemUid!,
        rank_uid: null,
        description: 'Delivery cost',
        is_menu: false,
        main_menu_price: null,
        menu_is_complete: false,
        menu_item_uid: null,
        is_proposition: false,
        voucher_id: null
      })
    }

    cartProducts.forEach((cartProduct: CartProduct) => {
      const isMenu = cartProduct.modifierGroups.some((modifierGroup: CartProductModifierGroupStateInterface) => {
        return this._modifierGroups[modifierGroup.id].type === 'rank'
      })

      const priceList = getProductPriceList(this._products[cartProduct.id], this._priceLists)
      let price = this._products[cartProduct.id].price[this._checkoutForm.orderingMethod.method as keyof Price].toString()

      if (priceList) {
        payload.header.price_list_id = priceList.price_list_id
        price = priceList.base_price.toString()
      }

      if (isMenu && cartProduct.quantity > 1) {
        for (let i = 0; i < cartProduct.quantity; i++) {
          this._createLines(payload, orderUid, UuidService.generate(), cartProduct, price, priceList, lang, isMenu)
        }
      } else {
        this._createLines(payload, orderUid, UuidService.generate(), cartProduct, price, priceList, lang, isMenu)
      }
    })

    /**
     * Creation of vouchers lines
     */
    if (brand?.settings.loyalty?.itemUid && vouchers?.length) {
      this._createVoucherLines(
        payload,
        vouchers,
        brand.settings.loyalty.itemUid,
        orderUid
      )
    }

    if (this._checkoutForm.paymentMethod?.id === 1 || this._totalIsZero) {
      payload.header.journal_id = firm.settings.journalId.toString()
    }

    payload.header.note = notes.join('\n')

    return payload
  }

  private _createVoucherLines(
    payload: CreateOrderPayload,
    vouchers: CustomerLoyaltyVoucher[],
    itemUid: string,
    orderUid: string
  ): void {
    vouchers.forEach((voucher: CustomerLoyaltyVoucher) => {
      payload.lines.push({
        sale_header_uid: orderUid,
        parent_sale_line_uid: null,
        uid: UuidService.generate(),
        quantity: -1,
        discount_rate: 0,
        unit_price: voucher.amount!.toString(),
        item_uid: itemUid,
        description: `Voucher: ${voucher.barcode}`,
        is_menu: false,
        rank_uid: null,
        menu_item_uid: null,
        menu_is_complete: false,
        main_menu_price: null,
        is_proposition: false,
        voucher_id: voucher.id
      })
    })
  }

  private _createLines(
    payload: CreateOrderPayload,
    orderUid: string | null,
    cartProductUid: string,
    cartProduct: CartProduct,
    price: string,
    priceList: PriceList | null,
    lang: string,
    isMenu: boolean
  ): void {
    payload.lines.push({
      sale_header_uid: orderUid!,
      parent_sale_line_uid: null,
      uid: cartProductUid,
      quantity: isMenu ? 1 : cartProduct.quantity,
      unit_price: price,
      price_list_id: priceList ? priceList.price_list_id : undefined,
      discount_rate: priceList ? priceList.discount_rate : 0,
      item_uid: this._products[cartProduct.id].uid,
      description: this._products[cartProduct.id].name[lang] || '',
      is_menu: isMenu,
      rank_uid: null,
      menu_item_uid: null,
      menu_is_complete: false,
      main_menu_price: isMenu ? price : null,
      is_proposition: false,
      voucher_id: null
    })

    cartProduct.modifierGroups.forEach((modifierGroup: CartProductModifierGroupStateInterface) => {
      this._createSubLines(
        orderUid!,
        lang,
        cartProductUid,
        this._products[cartProduct.id].uid,
        cartProduct.quantity,
        isMenu,
        this._modifierGroups[modifierGroup.id],
        payload,
        modifierGroup.modifiers
      )
    })
  }

  private _createSubLines(
    orderUid: string,
    lang: string,
    parentSaleLineUid: string,
    menuItemUid: string,
    parentQuantity: number,
    isMenu: boolean,
    modifierGroup: ModifierGroup,
    payload: CreateOrderPayload,
    modifiers: CartProductModifierStateInterface[]
  ) {
    modifiers.forEach((cartModifier: CartProductModifierStateInterface) => {
      if (cartModifier.action === 'rem') {
        return
      }

      let priceList: PriceList | null = null
      const saleLineUid = UuidService.generate()
      let modifier: Modifier = this._modifiers[cartModifier.id]
      const quantity = (isMenu ? 1 : parentQuantity) * cartModifier.quantity

      if (cartModifier.type === 'product') {
        modifier = convertProductToModifier(this._modifierGroups[modifierGroup.id], this._products[cartModifier.id])
        priceList = getProductPriceList(this._products[cartModifier.id], this._priceLists)
      }

      payload.lines.push({
        sale_header_uid: orderUid,
        parent_sale_line_uid: isMenu ? null : parentSaleLineUid,
        uid: saleLineUid,
        quantity,
        item_uid: modifier.uid,
        proposition_uid: this._modifierGroups[modifierGroup.id].uid,
        price_list_id: priceList ? priceList.price_list_id : undefined,
        discount_rate: priceList ? priceList.discount_rate : 0,
        unit_price: getModifierPrice(modifier, this._priceLists)[this._checkoutForm.orderingMethod.method as keyof Price].toString(),
        description: modifier.name[lang] || '',
        main_menu_price: null,
        is_menu: isMenu,
        rank_uid: isMenu ? modifierGroup.uid : null,
        menu_item_uid: isMenu ? menuItemUid : null,
        menu_is_complete: false,
        is_proposition: modifier.type !== 'product',
        voucher_id: null
      })

      const isModifierAMenu = cartModifier.modifierGroups.some((modifierGroup: CartProductModifierGroupStateInterface) => {
        return this._modifierGroups[modifierGroup.id].type === 'rank'
      })

      cartModifier.modifierGroups.forEach((modifierGroup: CartProductModifierGroupStateInterface) => {
        this._createSubLines(
          orderUid,
          lang,
          saleLineUid,
          menuItemUid,
          quantity,
          isModifierAMenu,
          this._modifierGroups[modifierGroup.id],
          payload,
          modifierGroup.modifiers
        )
      })
    })
  }

  private _buildConsumptionType(): number {
    switch (this._checkoutForm.orderingMethod.method) {
      case OrderingMethodNames.ON_THE_SPOT:
        return 1
      case OrderingMethodNames.DELIVERY:
        return 5
      default:
        return 2
    }
  }
}
