import { Box } from '@mui/material'
import ShoppingCart from '~/src/components/cart/shopping-cart'
import CheckoutPhysicalLoyaltyVoucherSection
  from '~/src/components/cart/shopping-cart-loyalty-voucher/ShoppingCartPhysicalLoyaltyVoucherSection'

const CheckoutShoppingCart = () => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    mb: 2
  }}>
    <ShoppingCart
      showActions={false}
      showQuantitySelector={false}
      showDeleteButton={false}
      showQuantityAsText
      enableCollapse
    />

    <CheckoutPhysicalLoyaltyVoucherSection />
  </Box>
)

export default CheckoutShoppingCart
