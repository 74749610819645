import {
  CustomPaymentMethod,
  OrderingMethodNames,
  PaymentMethod as PaymentMethodType,
  PaymentMethod,
  PaymentMethodMethodEnum,
  PickupPoint
} from '@eo-storefronts/eo-core'
import { useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  paymentMethods: PaymentMethod[],
}

const usePaymentMethods = (): ReturnsType => {
  const [ paymentMethods, setPaymentMethods ] = useState<PaymentMethod[]>([])
  const firm = useEoValue(FIRM_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)

  useEffect(() => {
    if (!firm || !firm.settings.paymentMethods.length) {
      return
    }

    let paymentMethods: PaymentMethodType[] = firm.settings.paymentMethods
      .filter(
        (method: PaymentMethodType) => method.orderingMethod as string === checkoutForm.orderingMethod.method
      )

    if (
      checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP
      && checkoutForm.orderingMethod.pickupPoint
      && !checkoutForm.orderingMethod.pickupPoint.mainBranch
    ) {
      const pickupPointDetails: PickupPoint[] = (firm.settings?.orderingMethods[OrderingMethodNames.PICKUP]?.pickupPoints || [])
        .filter((pickupPoint: PickupPoint) => pickupPoint.id === checkoutForm.orderingMethod.pickupPoint?.id)

      if (pickupPointDetails.length) {
        paymentMethods = pickupPointDetails[0]
          .paymentMethods.filter(
            (method: PaymentMethodType) => method.orderingMethod as string === checkoutForm.orderingMethod.method
          )
      }

    }

    if (checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY && firm.settings.customDeliveryPaymentMethods) {
      paymentMethods = [
        ...paymentMethods,
        ...firm.settings.customDeliveryPaymentMethods.map((customPaymentMethod: CustomPaymentMethod) => ({
          id: customPaymentMethod.id,
          method: customPaymentMethod.name,
          orderingMethod: PaymentMethodMethodEnum.CUSTOM
        }))
      ]
    }

    if (
      checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP
      && firm.settings.customPickupPaymentMethods
      && (!checkoutForm.orderingMethod.pickupPoint || checkoutForm.orderingMethod.pickupPoint?.mainBranch)
    ) {
      paymentMethods = [
        ...paymentMethods,
        ...firm.settings.customPickupPaymentMethods.map((customPaymentMethod: CustomPaymentMethod) => ({
          id: customPaymentMethod.id,
          method: customPaymentMethod.name,
          orderingMethod: PaymentMethodMethodEnum.CUSTOM
        }))
      ]
    }

    setPaymentMethods(paymentMethods)
  }, [ firm, checkoutForm.orderingMethod.pickupPoint, checkoutForm.orderingMethod ])

  return { paymentMethods }
}

export default usePaymentMethods
