import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import useFetchDeliveryAddressCost from '~/src/hooks/ordering-methods/useFetchDeliveryAddressCost'
import useCalculateDeliveryCosts from '~/src/hooks/fees/useCalculateDeliveryCosts'

const useValidateCheckoutDeliveryCostEffect = (): void => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { fetchDeliveryCost } = useFetchDeliveryAddressCost()
  const { calculateDeliveryCost } = useCalculateDeliveryCosts()

  useAsyncEffect(async () => {
    if (
      checkoutForm.orderingMethod.method !== OrderingMethodNames.DELIVERY
      || !checkoutForm.orderingMethod.address
    ) {
      return
    }

    await fetchDeliveryCost(checkoutForm.orderingMethod.address!)
    calculateDeliveryCost()
  }, [])
}

export default useValidateCheckoutDeliveryCostEffect
