import { Id, PaymentStateEnum, PaymentType } from '@eo-storefronts/eo-core'
import { useSearchParams } from 'react-router-dom'
import useLogPurchase from '~/src/hooks/analytics/ecommerce/useLogPurchase'
import useSetCustomerState from '~/src/hooks/customer/useSetCustomerState'
import { useFetchAllOrders } from '~/src/hooks/orders/useFetchAllOrders'
import useOpenExternalUrl from '~/src/hooks/router/useOpenExternalUrl'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { defaultPaymentRedirectUrls, payWith } from '~/src/services/PaymentService'
import { BRAND_STATE } from '~/src/stores/brand'
import { CHECKOUT_FORM_STATE, CHECKOUT_PAYMENT_STATE } from '~/src/stores/checkout'
import { CUSTOMER_STATE, GET_CUSTOMER_ID_SELECTOR, GET_CUSTOMER_UUID_SELECTOR } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  pay(orderId: Id): void,
}

const usePaymentMethodPay = (): ReturnsType => {
  const { log: logPurchase } = useLogPurchase()
  const { open: openExternalUrl } = useOpenExternalUrl()
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const { setCustomer } = useSetCustomerState()
  const { fetch: fetchAllOrders } = useFetchAllOrders()
  const customer = useEoValue(CUSTOMER_STATE)
  const customerId = useEoValue(GET_CUSTOMER_ID_SELECTOR)
  const customerUUID = useEoValue(GET_CUSTOMER_UUID_SELECTOR)
  const setPaymentState = useSetEoState<PaymentStateEnum|null>(CHECKOUT_PAYMENT_STATE)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const pay = (orderId: Id) => {
    if (!checkoutForm.paymentMethod || !orderId || !firm || !brand) {
      return
    }

    const firmId: Id = firm.id
    const paymentType: PaymentType = checkoutForm.paymentMethod.id as PaymentType

    localStorage.setItem('checkoutForm', JSON.stringify(checkoutForm))

    payWith(
      brand,
      paymentType,
      orderId,
      firmId,
      customerId,
      customerUUID,
      defaultPaymentRedirectUrls()
    )
      .then((response: any) => {
        openExternalUrl(response.paymentUrl, undefined, () => {
          searchParams.set('success', '')
          setSearchParams(searchParams)
        })
        logPurchase(orderId)
      })
      .catch(() => {
        setPaymentState(PaymentStateEnum.FAILED)
      })
      .finally(() => {
        if (customer) {
          Promise.all([
            fetchAllOrders(customer.id),
            setCustomer(undefined, true)
          ]).catch(() => {
            // Nothing
          })
        }
      })
  }

  return { pay }
}

export default usePaymentMethodPay
