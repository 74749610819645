import { PaymentMethod as PaymentMethodType } from '@eo-storefronts/eo-core'
import { FormControl, RadioGroup } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import NoPaymentMethod from '~/src/components/checkout/payments/NoPaymentMethod'
import usePaymentMethods from '~/src/hooks/checkout/usePaymentMethods'
import { useEoState } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import PaymentMethod from './PaymentMethod'

const PaymentMethods = () => {
  const [ checkoutForm, setCheckoutForm ] = useEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const { paymentMethods } = usePaymentMethods()

  const handlePaymentMethodSelectedChange = (event: ChangeEvent, value: string) => {
    const paymentMethod = paymentMethods.find((pm: PaymentMethodType) => pm.id === Number(value))

    if (!paymentMethod) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      paymentMethod: paymentMethod
    }))
  }

  useEffect(() => {
    if (paymentMethods.length === 1) {
      setCheckoutForm((state: CheckoutFormState) => ({
        ...state,
        paymentMethod: paymentMethods[0]
      }))
    }
  }, [ paymentMethods ])

  if (!paymentMethods.length) {
    return <NoPaymentMethod />
  }

  return (
    <FormControl>
      <RadioGroup
        value={checkoutForm.paymentMethod?.id || ''}
        onChange={handlePaymentMethodSelectedChange}
        sx={{
          flexDirection: 'row',
          gap: 1,
          p: 1
        }}
      >
        {paymentMethods.map((method: PaymentMethodType) => (
          <PaymentMethod
            key={method.id}
            selected={checkoutForm.paymentMethod?.id === method.id}
            paymentMethod={method}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default PaymentMethods
