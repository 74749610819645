import { DateUtils, OrderingMethodNames } from '@eo-storefronts/eo-core'
import useDeliveryTimeInvalidModal from '~/src/hooks/checkout/useDeliveryTimeInvalidModal'
import { useUpdateCheckoutTime } from '~/src/hooks/checkout/useUpdateCheckoutTime'
import { useConfirmTime } from '~/src/hooks/ordering-methods/useConfirmTime'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue } from '~/src/hooks/useEoState'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const useValidateCheckoutTimeEffect = (): void => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { confirmTimeRequest } = useConfirmTime()
  const { updateCheckoutTime } = useUpdateCheckoutTime()
  const { showModal } = useDeliveryTimeInvalidModal()

  useAsyncEffect(async () => {
    if (
      !checkoutForm.orderingMethod.time
      || !checkoutForm.orderingMethod.method
      || !checkoutForm.orderingMethod.timeslotId
      || checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
    ) {
      return
    }

    const response = await confirmTimeRequest(checkoutForm.orderingMethod.time)

    if (!response?.valid && response?.timestamp && checkoutForm.orderingMethod.asap) {
      updateCheckoutTime(DateUtils.addMinutes(30, new Date(response.timestamp)))
      return
    }

    if (!response?.valid) {
      showModal()
    }
  }, [
    checkoutForm.orderingMethod.method,
    checkoutForm.orderingMethod.time
  ])
}

export default useValidateCheckoutTimeEffect
