import {
  ApiConfiguration,
  Firm,
  Id,
  PaymentService,
  PaymentType,
  RedirectUrls
} from '@eo-storefronts/eo-core'
import RoutesEnum from '~/src/router/enums/routes.enum'
import EnvUtils from '~/src/utils/EnvUtils'

export const defaultPaymentRedirectUrls = (): RedirectUrls => ({
  acceptUrl: `${RoutesEnum.CHECKOUT}?success`,
  declineUrl: `${RoutesEnum.CHECKOUT}?declined`,
  exceptionUrl: `${RoutesEnum.CHECKOUT}?exception`,
  cancelUrl: `${RoutesEnum.CHECKOUT}?cancelled`
})

export const payWith = async (brand: Firm, paymentType: PaymentType, orderId: Id, firmId: Id, customerId: Id, uid: string, redirectUrls: RedirectUrls): Promise<unknown> => {
  ApiConfiguration.addOrReplaceHeader('store_id', firmId.toString())
  ApiConfiguration.addOrReplaceHeader('customer_id', customerId.toString())
  ApiConfiguration.addOrReplaceHeader('uid', uid.toString())

  const redirectUrlsGenerated: RedirectUrls = generateRedirectUrl(redirectUrls, brand)

  return PaymentService.pay(paymentType, orderId, redirectUrlsGenerated)
}

const generateReturnUrl = (path: string, brand: Firm): string => {
  const { host, pathname } = window.location
  let protocol = `${window.location.protocol}//`

  if (EnvUtils.isDeviceApp) {
    protocol = `${brand.settings.appUrl}://`
  }

  let baseUrl = `${protocol}${host}/${pathname.split('/')[1]}/${pathname.split('/')[2]}`

  if (!brand.isMultifirm) {
    baseUrl = `${protocol}${host}/${pathname.split('/')[1]}`
  }

  return `${baseUrl}${path}`
}

const generateRedirectUrl = (redirectUrls: RedirectUrls, brand: Firm): RedirectUrls => {
  return {
    acceptUrl: generateReturnUrl(redirectUrls.acceptUrl, brand),
    declineUrl: generateReturnUrl(redirectUrls.declineUrl, brand),
    exceptionUrl: generateReturnUrl(redirectUrls.exceptionUrl, brand),
    cancelUrl: generateReturnUrl(redirectUrls.cancelUrl, brand)
  }
}
