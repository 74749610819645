import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import InlineAddress from '~/src/components/customers/addresses/InlineAddress'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'

const CheckoutDeliveryAddress = () => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { t } = useTranslations()

  if (
    checkoutForm.orderingMethod.method !== OrderingMethodNames.DELIVERY
    || !checkoutForm.orderingMethod.address
  ) {
    return null
  }

  return (
    <Box>
      <Title>{t('checkout.deliveryAddress')}</Title>
      <Box sx={{
        display: 'inline-block',
        p: 1,
        my: 1,
        borderRadius: 2,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'secondary.contrastText',
        color: 'secondary.contrastText'
      }}>
        <InlineAddress address={checkoutForm.orderingMethod.address} />
      </Box>
    </Box>
  )
}

export default CheckoutDeliveryAddress
