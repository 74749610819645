import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import OrderingMethodsTimeSummary from '~/src/components/ordering-methods/OrderingMethodsTimeSummary'
import EatInSummary from '~/src/components/ordering-methods/EatInSummary'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, FROZEN_DELIVERY_METHOD_STATE } from '~/src/stores/checkout'
import OrderingMethodsSummaryBoxDeliveryIcon
  from '~/src/components/ordering-methods/OrderingMethodsSummaryBoxDeliveryIcon'

const OrderingMethodsSummaryBox = () => {
  const isOrderingMethodFrozen = useEoValue(FROZEN_DELIVERY_METHOD_STATE)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnClick = () => {
    if (isOrderingMethodFrozen) {
      return
    }

    responsiveDialogContext?.toggle && responsiveDialogContext.toggle()
  }

  if (!checkoutForm.orderingMethod.method) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 2,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'secondary.contrastText',
        color: 'secondary.contrastText',
        cursor: 'pointer'
      }}
      onClick={handleOnClick}
    >
      <Typography sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <OrderingMethodsSummaryBoxDeliveryIcon method={checkoutForm.orderingMethod.method} />
        {t(`orderingMethods.${checkoutForm.orderingMethod.method}`)}
      </Typography>
      {checkoutForm.orderingMethod.method !== OrderingMethodNames.ON_THE_SPOT && <OrderingMethodsTimeSummary />}
      {checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT && <EatInSummary />}
    </Box>
  )
}

export default OrderingMethodsSummaryBox
