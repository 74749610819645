import { Box, Checkbox, checkboxClasses, FormControlLabel, formControlLabelClasses } from '@mui/material'
import CustomerLoyaltyCurrentPointsLabel
  from '~/src/components/profile/account/loyalty/current-points-balance/CustomerLoyaltyCurrentPointsLabel'
import CustomerLoyaltyCurrentVoucherBalancePercentLabel
  from '~/src/components/profile/account/loyalty/current-voucher-balance/CustomerLoyaltyCurrentVoucherBalancePercentLabel'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_IS_POINT_SELECTOR, LOYALTY_IS_POINT_THRESHOLD_SELECTOR } from '~/src/stores/loyalty'
import { CHECKOUT_FORM_STATE, CheckoutFormState } from '~/src/stores/checkout'
import { CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR } from '~/src/stores/customer'
import { useTranslations } from '~/src/hooks/useTranslations'
import CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel
  from '~/src/components/profile/account/loyalty/current-voucher-balance/CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel'

const CheckoutLoyaltyPointSelector = () => {
  const { t } = useTranslations()
  const [ checkoutState, setCheckoutState ] = useEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)
  const isLoyatyPoint = useEoValue(LOYALTY_IS_POINT_SELECTOR)
  const isLoyaltyPointThreshold = useEoValue(LOYALTY_IS_POINT_THRESHOLD_SELECTOR)
  const customerLoyaltyVouchersValue = useEoValue(CUSTOMER_LOYALTY_VOUCHERS_BALANCE_SELECTOR)

  const handleOnChange = () => {
    setCheckoutState((state: CheckoutFormState) => ({
      ...state,
      paymentMethod: null,
      useLoyaltyPoints: !state.useLoyaltyPoints
    }))
  }

  return (
    <FormControlLabel
      control={<Checkbox
        checked={checkoutState.useLoyaltyPoints}
        onChange={handleOnChange}
        disabled={!customerLoyaltyVouchersValue}
        inputProps={{ 'aria-label': 'controlled' }}
      />}
      label={
        <Box sx={{
          display: 'inline-flex',
          gap: 1
        }}>
          {t('global.use')}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            '*': {
              fontSize: '.85rem !important'
            },
            'span:last-child': {
              fontSize: '.65rem !important'
            }
          }}>
            {isLoyatyPoint && <CustomerLoyaltyCurrentPointsLabel/>}
            {isLoyaltyPointThreshold && <CustomerLoyaltyCurrentVoucherBalancePointsTresholdLabel/>}
            {!isLoyatyPoint && !isLoyaltyPointThreshold && <CustomerLoyaltyCurrentVoucherBalancePercentLabel/>}
          </Box>
        </Box>
      }
      sx={{
        width: 'max-content',
        mx: '0 !important',
        px: 1,
        py: 2,
        pr: 3,
        backgroundColor: !checkoutState.useLoyaltyPoints ? 'background.main' : undefined,
        color: !checkoutState.useLoyaltyPoints ? 'background.contrastText' : undefined,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: !checkoutState.useLoyaltyPoints ? 'background.main' : 'primary.main',
        borderRadius: '8px',
        display: 'inline-flex',
        gap: 1,
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
        [`.${formControlLabelClasses.label}`]: {
          width: 'inherit'
        },
        [`.${checkboxClasses.root}`]: {
          color: 'inherit'
        }
      }}
    />
  )
}

export default CheckoutLoyaltyPointSelector
