import { Customer } from '@eo-storefronts/eo-core'
import { Box, TextField, Typography } from '@mui/material'
import { ChangeEvent } from 'react'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CUSTOMER_STATE } from '~/src/stores/customer'

const CheckoutInvoiceCompanyDetails = () => {
  const { t } = useTranslations()
  const [ customer, setCustomer ] = useEoState(CUSTOMER_STATE)

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomer((state: Customer | null) => {
      if (!state) {
        return null
      }

      return {
        ...state,
        [event.target.name]: event.target.value
      }
    })
  }

  if (!customer) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Typography>{t('checkout.invoice.companyDetails')}</Typography>
      <TextField
        name='company'
        size='small'
        sx={{
          backgroundColor: 'container.main',
          color: 'container.contrastText'
        }}
        label={`${t('checkout.invoice.companyName')} *`}
        value={customer.company}
        error={customer.company === ''}
        onChange={handleOnChange}
      />
      <TextField
        name='invoiceReference'
        size='small'
        sx={{
          backgroundColor: 'container.main',
          color: 'container.contrastText'
        }}
        label={t('checkout.invoice.reference')}
        value={customer.invoiceReference}
        onChange={handleOnChange}
      />
      <TextField
        name='vatNumber'
        size='small'
        sx={{
          backgroundColor: 'container.main',
          color: 'container.contrastText'
        }}
        label={`${t('checkout.invoice.vatNumber')} *`}
        value={customer.vatNumber}
        error={customer.vatNumber === ''}
        onChange={handleOnChange}
      />
    </Box>
  )
}

export default CheckoutInvoiceCompanyDetails
