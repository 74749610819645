import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'

interface ReturnsType {
  redirect(): void,
}

const useRedirectAfterCheckout = (): ReturnsType => {
  const authState = useEoValue(AUTHENTICATION_STATE)
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()

  const redirect = () => {
    if (!authState.loggedInAs) {
      return
    }

    return push(resolve(`${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}?thank-you`))
  }

  return { redirect }
}

export default useRedirectAfterCheckout
