import { OrderingMethodNames } from '@eo-storefronts/eo-core'
import DeliveryDining from '@mui/icons-material/DeliveryDining'
import TableRestaurant from '@mui/icons-material/TableRestaurant'
import LocalMall from '@mui/icons-material/LocalMall'

interface Props {
  method: OrderingMethodNames,
}

const OrderingMethodsSummaryBoxDeliveryIcon = ({ method }: Props) => {
  switch (method) {
    case OrderingMethodNames.DELIVERY:
      return <DeliveryDining />
    case OrderingMethodNames.PICKUP:
      return <LocalMall />
    case OrderingMethodNames.ON_THE_SPOT:
      return <TableRestaurant />
    default:
      return null
  }
}

export default OrderingMethodsSummaryBoxDeliveryIcon
