import {
  BasicResponse,
  CreateOrderPayload,
  PaymentMethodMethodEnum,
  PaymentStateEnum,
  PriceList
} from '@eo-storefronts/eo-core'
import { useRef } from 'react'
import OrderValidatingPaymentResponsiveDialog from '~/src/components/orders/OrderValidatingPaymentResponsiveDialog'
import deepEqual from '~/src/helpers/deepEqual'
import usePaymentMethodPay from '~/src/hooks/checkout/usePaymentMethodPay'
import useRedirectAfterCheckout from '~/src/hooks/checkout/useRedirectAfterCheckout'
import useFetchCustomerLoyalty from '~/src/hooks/customer/useFetchCustomerLoyalty'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import GenerateOrderPayloadService, { RawOrderInfo } from '~/src/services/GenerateOrderPayloadService'
import { createOrder } from '~/src/services/OrderService'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { CART_OF_FIRM_SELECTOR, CART_STATE, CART_TOTAL_IS_ZERO_SELECTOR, CartState } from '~/src/stores/cart'
import {
  CHECKOUT_FORM_STATE,
  CHECKOUT_ORDER_ID,
  CHECKOUT_PAYMENT_STATE,
  CHECKOUT_SLOT_ID,
  OrderIdState
} from '~/src/stores/checkout'
import { CUSTOMER_STATE, GET_CUSTOMER_LOYALTY_VOUCHERS_TO_USE_FOR_ORDER_SELECTOR } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR } from '~/src/stores/loyalty'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'
import { LAST_GUEST_ORDER_ID_STATE } from '~/src/stores/orders'
import { PRODUCTS_STATE } from '~/src/stores/product'
import { PRICE_LIST_STATE } from '~/src/stores/product/price-list'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

interface ReturnsType {
  placeOrder(): Promise<void>,
}

const usePlaceOrder = (): ReturnsType => {
  const { pay } = usePaymentMethodPay()
  const { present, dismiss } = useDialog()
  const lang = useEoValue(LANGUAGE_STATE)
  const brand = useEoValue(BRAND_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const customer = useEoValue(CUSTOMER_STATE)
  const customerLoyaltyVoucherToUse = useEoValue(GET_CUSTOMER_LOYALTY_VOUCHERS_TO_USE_FOR_ORDER_SELECTOR)
  const auth = useEoValue(AUTHENTICATION_STATE)
  const cartProducts = useEoValue(CART_OF_FIRM_SELECTOR)
  const products = useEoValue(PRODUCTS_STATE)
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const slotId = useEoValue(CHECKOUT_SLOT_ID)
  const setLastGuestOrderId = useSetEoState(LAST_GUEST_ORDER_ID_STATE)
  const setPaymentState = useSetEoState<PaymentStateEnum | null>(CHECKOUT_PAYMENT_STATE)
  const setOrderId = useSetEoState<OrderIdState>(CHECKOUT_ORDER_ID)
  const setCartState = useSetEoState(CART_STATE)
  const totalIsZero = useEoValue(CART_TOTAL_IS_ZERO_SELECTOR)
  const orderRef = useRef<CreateOrderPayload | null>(null)
  const { redirect } = useRedirectAfterCheckout()
  const { fetchLoyaltyAndSetState } = useFetchCustomerLoyalty()
  const isLoyaltyEnabled = useEoValue(LOYALTY_IS_ENABLED_AND_CUSTOMER_IS_LOGGED_IN_SELECTOR)
  const priceLists: PriceList[] = useEoValue(PRICE_LIST_STATE)
  const generateOrderPayloadService = new GenerateOrderPayloadService(
    checkoutForm,
    priceLists,
    products,
    modifierGroups,
    modifiers,
    auth,
    customer,
    totalIsZero
  )

  const _generatePayload = (): CreateOrderPayload => {
    const payload: RawOrderInfo = {
      brand,
      firm,
      slotId,
      lang,
      cartProducts,
      vouchers: []
    }

    if (isLoyaltyEnabled && checkoutForm.useLoyaltyPoints) {
      payload.vouchers = customerLoyaltyVoucherToUse
    } else if (!isLoyaltyEnabled && checkoutForm.physicalVoucher) {
      payload.vouchers = [ checkoutForm.physicalVoucher ]
    }

    return generateOrderPayloadService.generate(payload)
  }

  const placeOrder = async () => {
    if (!firm) {
      return
    }

    const orderPayload = _generatePayload()
    setPaymentState(null)

    if (
      (
        Number(checkoutForm.paymentMethod?.id) === 1
        || checkoutForm.paymentMethod?.method === PaymentMethodMethodEnum.CUSTOM
        || totalIsZero
      )
      && deepEqual(orderRef.current, orderPayload)
    ) {
      return redirect()
    }

    present(OrderValidatingPaymentResponsiveDialog, DialogNameEnum.ORDER_VALIDATING_PAYMENT, {
      open: true
    })

    try {
      const response: BasicResponse = await createOrder(
        orderPayload,
        firm.id
      )

      orderRef.current = orderPayload
      setLastGuestOrderId(response.id)
      setOrderId((state: OrderIdState) => ({
        ...state,
        [firm.id]: response.id
      }))

      /**
       * Redirect the user to the UPCOMING_ORDER page
       */
      if (Number(checkoutForm.paymentMethod?.id) === 1 || totalIsZero) {
        setCartState((cart: CartState) => ({
          ...cart,
          [firm.id]: []
        }))
        setPaymentState(PaymentStateEnum.PAID)

        setTimeout(() => {
          dismiss(DialogNameEnum.ORDER_VALIDATING_PAYMENT)
          redirect()
        }, 3000)
      } else {
        pay(response.id)
      }

      if (customer !== null) {
        await fetchLoyaltyAndSetState(customer?.uid)
      }
    } catch (e) {
      setPaymentState(PaymentStateEnum.FAILED)
      throw e
    }
  }

  return { placeOrder }
}

export default usePlaceOrder
