import { PaymentService, PaymentStateEnum } from '@eo-storefronts/eo-core'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import OrderValidatingPaymentResponsiveDialog from '~/src/components/orders/OrderValidatingPaymentResponsiveDialog'
import useRedirectAfterCheckout from '~/src/hooks/checkout/useRedirectAfterCheckout'
import useWindowFocus from '~/src/hooks/layout/useWindowFocus'
import useDialog from '~/src/hooks/useDialog'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { CART_STATE, CartState } from '~/src/stores/cart'
import { CHECKOUT_ORDER_ID_OF_FIRM_SELECTOR, CHECKOUT_PAYMENT_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

const useValidateCheckoutPaymentStatusEffect = (): void => {
  const orderId = useEoValue(CHECKOUT_ORDER_ID_OF_FIRM_SELECTOR)
  const firm = useEoValue(FIRM_SELECTOR)
  const setPaymentState = useSetEoState<PaymentStateEnum|null>(CHECKOUT_PAYMENT_STATE)
  const setCartState = useSetEoState(CART_STATE)
  const { present, dismiss } = useDialog()
  const { redirect } = useRedirectAfterCheckout()
  const retryCountRef = useRef<number>(0)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const modalShown = useRef<Boolean>(false)
  const [ searchParams ] = useSearchParams()
  const { hasFocus } = useWindowFocus()

  const checkPaymentStatus = async (currentRetry = 0) => {
    if (!orderId) {
      return
    }

    if (!modalShown.current) {
      modalShown.current = true
      present(OrderValidatingPaymentResponsiveDialog, DialogNameEnum.ORDER_VALIDATING_PAYMENT, {
        open: true
      })
    }

    const response = await PaymentService.getPaymentStatus(orderId)

    if (response.paid) {
      setCartState((cart: CartState) => ({
        ...cart,
        [firm!.id]: []
      }))
      setPaymentState(PaymentStateEnum.PAID)

      timeoutRef.current = setTimeout(() => {
        dismiss(DialogNameEnum.ORDER_VALIDATING_PAYMENT)
        redirect()
      }, 3000)
      return
    }

    if ([ 'declined', 'exception' ].some(param => searchParams.has(param))) {
      setPaymentState(PaymentStateEnum.FAILED)
    } else if (searchParams.has('cancelled')) {
      setPaymentState(PaymentStateEnum.CANCELED)
    } else if (searchParams.has('success') || currentRetry > 0) {
      if (currentRetry > 20) {
        setPaymentState(PaymentStateEnum.TIMEOUT)
      } else if (response.status === PaymentStateEnum.CANCELED) {
        setPaymentState(PaymentStateEnum.CANCELED)
      } else if (response.status && response.status !== PaymentStateEnum.PAID) {
        setPaymentState(PaymentStateEnum.FAILED)
      } else {
        retryCountRef.current = currentRetry + 1
        timeoutRef.current = setTimeout(() => {
          checkPaymentStatus(retryCountRef.current)
        }, 3000)
      }
    } else {
      dismiss(DialogNameEnum.ORDER_VALIDATING_PAYMENT)
    }
  }

  useEffect(() => {
    if (!orderId || !hasFocus) {
      return
    }

    setPaymentState(null)

    void checkPaymentStatus()

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }

  }, [ searchParams, hasFocus ])
}

export default useValidateCheckoutPaymentStatusEffect
