import { PaymentMethod as PaymentMethodType } from '@eo-storefronts/eo-core'
import { FormControlLabel, Radio, radioClasses } from '@mui/material'
import PaymentMethodLabel from '~/src/components/checkout/payments/PaymentMethodLabel'

interface Props {
  selected: boolean,
  paymentMethod: PaymentMethodType,
}

const PaymentMethod = ({ selected, paymentMethod }: Props) => (
  <FormControlLabel
    value={paymentMethod.id}
    sx={{
      backgroundColor: !selected ? 'secondary.main' : undefined,
      color: !selected ? 'secondary.contrastText' : undefined,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: !selected ? 'secondary.contrastText' : 'primary.main',
      borderRadius: 'var(--btn-border-radius)',
      pr: 2
    }}
    control={
      <Radio
        sx={{
          [`&:not(.${radioClasses.checked})`]: {
            color: 'secondary.contrastText'
          }
        }}/>
    }
    label={<PaymentMethodLabel method={paymentMethod} />}
  />
)

export default PaymentMethod
