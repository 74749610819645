import { OrderingMethodNames, PaymentMethod, PaymentMethodMethodEnum } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface ReturnsType {
  label: string,
}

const usePaymentMethodLabel = (method: PaymentMethod): ReturnsType => {
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const firm = useEoValue(FIRM_SELECTOR)
  const lang = useEoValue(LANGUAGE_STATE)
  const { t } = useTranslations()

  const _getLabel = useCallback((): string => {
    if (!firm) {
      return ''
    }

    if (
      method.id === 1
      && checkoutForm.orderingMethod.method === OrderingMethodNames.DELIVERY
    ) {
      return firm.labels?.cash_delivery[lang] || ''
    }

    if (
      method.id === 1
      && checkoutForm.orderingMethod.method === OrderingMethodNames.PICKUP
    ) {
      return firm.labels?.cash_pickup[lang] || ''
    }

    if (
      method.id === 1
      && checkoutForm.orderingMethod.method === OrderingMethodNames.ON_THE_SPOT
    ) {
      return firm.labels?.cash_table[lang] || ''
    }

    if (method.id === 2) {
      return 'Bancontact'
    }

    if (method.id === 3) {
      return 'iDeal'
    }

    if (method.method === PaymentMethodMethodEnum.CUSTOM) {
      return method.method
    }

    return t('checkout.onlinePayment')
  }, [ firm, checkoutForm ])

  return { label: _getLabel() }
}

export default usePaymentMethodLabel
