import CheckoutLoyaltyTitle from '~/src/components/checkout/loyalty/CheckoutLoyaltyTitle'
import CheckoutLoyaltyPointSelector from '~/src/components/checkout/loyalty/CheckoutLoyaltyPointSelector'
import { Box } from '@mui/material'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOYALTY_CAN_BE_APPLIED_SELECTOR } from '~/src/stores/loyalty'
import { IS_LOGGED_IN_AS_SELECTOR_FAMILY } from '~/src/stores/authentication'

const CheckoutLoyaltySection = () => {
  const loggedInAsCustomer = useEoValue(IS_LOGGED_IN_AS_SELECTOR_FAMILY('customer'))
  const canApplyLoyalty = useEoValue(LOYALTY_CAN_BE_APPLIED_SELECTOR)

  if (!canApplyLoyalty || !loggedInAsCustomer) {
    return null
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      my: 1
    }}>
      <CheckoutLoyaltyTitle/>

      <CheckoutLoyaltyPointSelector/>
    </Box>
  )
}

export default CheckoutLoyaltySection
