import { Alert } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

const NoPaymentMethod = () => {
  const { t } = useTranslations()

  return (
    <Alert
      severity='info'
      sx={{
        alignItems: 'center'
      }}
    >
      {t('checkout.errors.noPaymentMethods')}
    </Alert>
  )
}

export default NoPaymentMethod
