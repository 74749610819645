import { ChangeEvent } from 'react'
import { useEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CheckoutFormState, CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import TextField from '~/src/components/mui-wrappers/styled/text-field'

const CheckoutMessage = () => {
  const { t } = useTranslations()
  const [ checkoutForm, setCheckoutForm ] = useEoState(CHECKOUT_FORM_STATE)

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      moreInformation: event.target.value
    }))
  }

  return (
    <TextField
      sx={{ my: 1 }}
      fullWidth
      color='container'
      variant='outlined'
      size='small'
      label={t('checkout.giveUsMoreInfo')}
      multiline
      rows={4}
      value={checkoutForm.moreInformation || ''}
      onChange={handleOnChange}
    />
  )
}

export default CheckoutMessage
