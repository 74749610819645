import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import Title from '~/src/components/mui-wrappers/Title'
import OrderingMethods from '~/src/components/ordering-methods/OrderingMethods'
import OrderingMethodsSummaryBox from '~/src/components/ordering-methods/OrderingMethodsSummaryBox'
import useReLoginAsGuestEffect from '~/src/hooks/checkout/useReLoginAsGuestEffect'
import { useTranslations } from '~/src/hooks/useTranslations'

const OrderingMethodsSummaryBoxContainer = () => {
  const { t } = useTranslations()
  const { check } = useReLoginAsGuestEffect()

  const _handleOnClose = () => {
    check()
  }

  return (
    <>
      <Title>{t('checkout.deliveryInfo')}</Title>
      <ResponsiveDialog
        Title={<Title>{t('checkout.deliveryInfo')}</Title>}
        activator={<OrderingMethodsSummaryBox />}
        onClose={_handleOnClose}
        maxWidth='xs'
        fullWidth
      >
        <OrderingMethods />
      </ResponsiveDialog>
    </>
  )
}

export default OrderingMethodsSummaryBoxContainer
